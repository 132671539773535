import React from 'react'
import logo from './Compnents/Images/Logo.png'
import { Link } from 'react-router-dom'
import { FaInstagram, FaFacebook, FaTwitter } from 'react-icons/fa'
// import { useLocation } from 'react-router-dom'

import '../src/Footer.css'
const Footer = () => {
  //  const routepath = useLocation()
  //  useEffect(() => {
  //    window.scrollTo(0, 0)
  //  }, [routepath])
  return (
    <>
      <div className='container-fluid d-flex justify-content-center align-items-center flex-column mt- footer w-100 pt-5 pb-5'>
        <div className='topfooter d-md-flex justify-content-around w-100 mb-5 justify-cotent-md-around align-items-center flex-column flex-md-row '>
          <div className='logo2'>
            <img src={logo} alt='LOGO' className='' />
          </div>
          <ul className='nav_menu d-flex flex-column flex-md-row p-0 mt-3 mt-md-0'>
            <li className='linkfooter mx-md-3 text-capitalize dropdown'>
              <Link
                to=''
                className='dropdown-toggle linkfooter1'
                id='dropdownMenuLink'
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                Services
              </Link>
              <ul
                class='dropdown-menu linkfooter1dropdown'
                aria-labelledby='dropdownMenuLink'
              >
                <li>
                  <Link to='/nonap' className='dropdown-item linkfooter'>
                    No Nap Kit
                  </Link>
                </li>
                <li>
                  <Link to='/training' className='dropdown-item linkfooter'>
                    Training
                  </Link>
                </li>
                <li>
                  <Link
                    to='/speedlimiters'
                    className='dropdown-item linkfooter'
                  >
                    Speed Limiters Devices
                  </Link>
                </li>
                {/* <li>
                  <Link to='/freetips' className='dropdown-item linkfooter'>
                    Free Tips
                  </Link>
                </li> */}
                <li>
                  <Link to='/gpstracking' className='dropdown-item linkfooter'>
                    GPS Tracking
                  </Link>
                </li>
              </ul>
            </li>
            <li className='linkfooter mx-md-3 text-capitalize'>
              <Link to='/freetips' className='linkfooter1'>
                Free Tips
              </Link>
            </li>
            <li className='linkfooter mx-md-3 text-capitalize'>
              <Link to='/AboutUs' className='linkfooter1'>
                about us
              </Link>
            </li>
            <li className='linkfooter mx-md-3 text-capitalize'>
              <Link to='/contactUs' className='linkfooter1'>
                contact us
              </Link>
            </li>
          </ul>
        </div>
        <div className='middlefooter d-flex justify-content-center align-items-center w-100 flex-column'>
          <div className='locationsandcontacts d-flex justify-content-around w-100 flex-column flex-md-row'>
            <div className='lag mb-4'>
              <p>Lagos</p>
              <p>1,Elkanah St.,(Nigerland Hall)</p>
              <p> St Josephs, Mangoro B/S,</p>
              <p>
                Phone: <br />
                0803 523 5884
              </p>
            </div>
            <div className='lag mb-4'>
              <p>Abuja, FCT.</p>
              <p> C3, Nkwegu Plaza,</p>
              <p>
                {' '}
                1088 Durumi District, <br />
                Area 1,Garki,Abuja,Fct
              </p>
              <p>
                Phone: <br />
                0802 086 0001
              </p>
            </div>
            <div className='email mb-4'>
              <p>Email: info@nonapnigeria.com</p>
              <p> Web: www.nonapnigeria.com</p>
            </div>
          </div>
          <div class='home__socials d-flex align-items-start flex-row mb-5 mb-md-0'>
            <a
              href='http://www.facebook.com/nonap.nigeria/'
              class='home__social-links'
            >
              <FaFacebook className='i' />
              <span>Like on Facebook</span>
            </a>
            {/* <div className='line d-none d-md-block'></div> */}
            <a href='http://www.instagram.com/' class='home__social-links'>
              <FaInstagram className='i' />
              <span>Follow on Instagram</span>
            </a>
            {/* <div className='line d-none d-md-block'></div> */}
            <a
              href='http://www.twitter.com/shinetrackg'
              class='home__social-links'
            >
              <FaTwitter className='i' />
              <span>Follow on Twitter</span>
            </a>
          </div>
          <p
            className='copyright
          '
          >
            Copyright © 2023 No Nap Nigeria Ltd. All Rights Reserved. Webserver Powered by Shinetrack <a href="http://www.webdesignlagos.com">Webdesign lagos</a>
          </p>
        </div>
      </div>
    </>
  )
}

export default Footer
