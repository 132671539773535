import React from 'react'
import '../Speedlimiter/Speedlimit.css'
// import packageimg from '../../../Images/packages.png'
import speelimiterimgg from '../../../Images/Speedlimiterimgggg.png'







const speedlimit = () => {
  //  const routepath = useLocation()
  return (
    <>
      <div className='speedlimit'>
        <div className='speedlimitscript d-flex justify-content-center align-items-center flex-column  '>
          <h1>speedlimiter</h1>
          <p>
            Inter Our Purple Global Digital Brands of Speed Limiters provide
            remote asset management in Nigeria. These products are uniquely
            designed to address all the tracking concerns of our clients’
            transportation and other assets. They come as stand-alone units,
            with built-in configuration and management systems. There is no need
            for computers or other equipment to administer the Purple Digital
            Brand of speed limiters.
          </p>
        </div>
      </div>
      <div className='packages d-flex justify-content-center align-items-center flex-column'>
        <div className='speedlimitersect d-flex justify-content-center align-items-center flex-column'>
          <div className='package packagecontent'>
            <ul>
              <h5>Each digital Speed Limiter package comprises of:</h5>

              <li> A digital Speed Limiter</li>
              <li>
                A smart card to record and store vehicle drive data, which can
                be downloaded via a USB port to a personal computer
              </li>
              <li> A digital printer</li>
            </ul>
            <div className='speelimiterimg w-100'>
              <img
                src={speelimiterimgg}
                alt=''
                srcset=''
                className='img-fluid'
              />
            </div>
          </div>
        </div>
        <div className='package'>
          <div className='packagecontent'>
            <h5>USB Master Port and Printer</h5>
            <p>
              The digital speed limiters has a USB master port which is used to:
            </p>
            <ul>
              <li>
                {' '}
                Set up the unit, including vehicle details, unique driver
                settings, and the type of device on the vehicle to be
                controlled.
              </li>
              <li>Automatically download general reports.</li>
              <li>
                {' '}
                Download driving and vehicle data, including vehicle
                registration, governor ID, vehicle owner name, driver name,
                driving speed, duration on road, date, etc.
              </li>
            </ul>
            <p>
              The vehicle details listed above may be printed by simply pressing
              on the “Print” button.
            </p>
          </div>
          <div className='packagecontent'>
            <h5>Data Logger</h5>
            <p>
              The data-logger unit records data from the speed-limiting device.
              This data includes:
            </p>
            <ul>
              <li> All necessary vehicle identification</li>
              <li> Vehicle owner details</li>
              <li>Driver details</li>
              <li>Date and time specifications</li>
              <li> Digital Speed limiters ID</li>
              <li> Recorded speed of the vehicle for a period of 3 days</li>
              <li> Speeding and power-interrupt information</li>
            </ul>
            <p>
              The data logger instantly logs on any situation where the vehicle
              reaches its pre-determined speed limit, when its battery has been
              disconnected or when the power to the speed limiter/data logger
              has been tampered with.
            </p>
            <p>
              This data is accessible via the USB interface to any USB-enabled
              device, and does not include the instantly-recorded speed data
              that is available via the digital printer.
            </p>
          </div>
          <div className='packagecontents'>
            <h5>Device Security</h5>
            <p>
              The Speed Limiters have fail safe mechanisms that prevent
              tampering. Any attempts to tamper with these devices is recorded
              in realtime and results in the vehicle shutting down, stopping
              further use.
            </p>
          </div>
          <div className='packagecontents'>
            <h5>Speed Warnings</h5>
            <p>
              Our units are equipped with an audible buzzer that warns the
              driver to slow down once the vehicle is within 2% of the
              programmed speed limit. They also have LED indicators on the front
              panel that visibly display a speed warning.
            </p>
          </div>
          <div className='packagecontent'>
            <h5>Electrical Specifications</h5>

            <ul>
              <li> Supply voltage: +8 V to 35 V</li>
              <li> Average supply current: less than (3mA)</li>
              <li> Operating temperature” -40 Celsius to +85 Celsius</li>
              <li>
                Our Devices and Engineers are Officially Certified by the FRSC.
              </li>
              <li>
                {' '}
                The Speed Limiters are offered separately apart from our car
                tracking devices, in response to the Ministry of
                Transportation’s and FRSC directive to avoid confusion between
                GPS speed limiters and our unique digital Speed Limiter model.
              </li>
              <li>
                {' '}
                Mechanical Vehicles that dont come with Censors will be required
                to include Censors in their package, which will also be
                installed by us to detect the Speed Limiter device.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default speedlimit
