import React from 'react'
// import BookUs from '../Compnents/landingpages/Home/BookUs'
// import Contact from '../Compnents/landingpages/Home/Contact.js'
import Faq from '../Compnents/landingpages/Home/Faq'
import Hero from '../Compnents/landingpages/Home/Hero'
import Ourpartners from '../Compnents/landingpages/Home/Ourpartners'
import OurServices from '../Compnents/landingpages/Home/OurServices'
import Footer from '../Footer'
import Header from '../Header'
import Layout from './Layout'
// import { useLocation } from 'react-router-dom'


const Home = (props) => {
    //  const routepath = useLocation()
    //  useEffect(() => {
    //    window.scrollTo(0, 0)
    //  }, [routepath])
  return (
    <>
      <Layout>
        <Header/>
        <Hero />
        <Ourpartners />
        <OurServices />
        {/* <BookUs /> */}
        {/* <Contact/> */}
        <Faq />
        <Footer/>
      </Layout>
    </>
  )
}

export default Home