import React, {useState} from 'react'
import Header from '../Header'
import { MdLocationOn, MdMail } from 'react-icons/md'
import { FaPhoneAlt } from 'react-icons/fa'
// import contactimg from '../../Images/contactimg.png'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-toastify/dist/ReactToastify.min.css'
import '../Pages/Contact.css'
import Layout from './Layout'
import Footer from '../Footer'
// import { useLocation } from 'react-router-dom'
const Contact = (props) => {

  const [loader, setLoader] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [pNumber, setpNumber] = useState('')
  // const routepath = useLocation()
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [routepath])
  // const form = useRef()
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoader(true)
    emailjs
      .sendForm(
        'service_kocltq7',
        'template_64bso6k',
        e.target,
        'g3gO5uKGVPTlQa3or'
      )
      .then(
        (result) => {
          setLoader(false)
          console.log(result.text)
          toast.success(
            'Your message was sent successfully. Thanks for contacting us!'
          )
          setFirstName('')
          setLastName('')
          setEmail('')
          setMessage('')
          setpNumber('')
        },
        (error) => {
          console.log(error.text)
          toast.error('Oops, something went wrong. Please try again later.')
        }
      )
  }

  return (
    <>
      <Layout>
        <ToastContainer
          position='top-center'
          autoClose={3000}
          hideProgressBar={true}
        />
        <Header />
        <div className='container-fluid d-flex justify-content-center align-items-center flex-column contact pb-5'>
          <div className='row align-items-center justify-content-center m-0'>
            <div className='form col-md-10 col-lg-7 col-sm-11 col-11 py-5 px-2 m-0'>
              <h1>Get In Touch</h1>
              <div className='loctionscontacts w-100'>
                <div className='location d-flex justify-content-center align-items-center my-3 flex-column '>
                  <MdLocationOn className='loctaionicon' />
                  <div className='locationcontent'>
                    Lagos 1,Elkanah St.,(Nigerland Hall) St Josephs, Mangoro
                    B/S,
                  </div>
                </div>
                <div className='location d-flex justify-content-center align-items-center flex-column'>
                  <FaPhoneAlt className='loctaionicon' />
                  <div className='locationcontent'>0803 523 5884</div>
                  <div className='locationcontent'>0802 086 0001</div>
                </div>
                <div className='location d-flex justify-content-center align-items-center flex-column'>
                  <MdMail className='loctaionicon' />
                  <div className='locationcontent'>info@nonapnigeria.com</div>
                  <div className='locationcontent'>www.nonapnigeria.com</div>
                </div>
              </div>
              <form
                action=''
                className='mt-4 d-flex justify-content-center flex-column align-items-center '
                // ref={form}
                onSubmit={handleSubmit}
              >
                <div className='row m-0'>
                  <div className='col-sm-6 px-1'>
                    <input
                      type='text'
                      name='user_name'
                      placeholder='First Name'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      // value={formData.firstName}
                      // onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='col-sm-6 px-1'>
                    <input
                      type='text'
                      name='user_name'
                      placeholder='last Name'
                      // value={formData.lastName}
                      // onChange={handleChange}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                  <div className='col-sm-6 px-1'>
                    <input
                      type='email'
                      name='user_email'
                      placeholder='Email'
                      // value={formData.email}
                      // onChange={handleChange}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className='col-sm-6 px-1'>
                    <input
                      type='tel'
                      name='number'
                      placeholder='Phone number'
                      // value={formData.phoneNumber}
                      // onChange={handleChange}
                      value={pNumber}
                      onChange={(e) => setpNumber(e.target.value)}
                      required
                    />
                  </div>
                  <div className='col'>
                    <textarea
                      name='message'
                      id=''
                      cols='30'
                      rows='6'
                      placeholder='Message'
                      // value={formData.message}
                      // onChange={handleChange}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>
                </div>
                <button type='submit'>
                  {loader ? <div class='loader'></div> : ' send'}
                </button>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    </>
  )
}

export default Contact
