import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Layout from './Layout'
import Speedlimit from '../Compnents/landingpages/Services/Speedlimiter/Speedlimit'
// import { useLocation } from 'react-router-dom'

const Speedlimiters = (props) => {
  // const routepath = useLocation()
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [routepath])
  return (
    <div>
      <Layout>
        <Header />
        <Speedlimit />
        <Footer />
      </Layout>
    </div>
  )
}

export default Speedlimiters
