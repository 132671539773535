import React from 'react'
// import seminarimg from '../../../Images/Rectangle 84.png'
import workshopimg1 from '../../../Images/Rectangle 113.png'
import workshopimg2 from '../../../Images/Rectangle 117.png'
import workshopimg3 from '../../../Images/Rectangle 116.png'
// import { useLocation } from 'react-router-dom'

import '../Training/Seminar.css'

const Seminars = () => {
  return (
    <>
      <div className='training d-flex align-items-center justify-content-center flex-column'>
        <div className='train'>
          <div className='seminar d-flex justify-content-start w-100 flex-column-reverse align-items-'>
            {/* <div className='imgsemi'>
              <img src={seminarimg} alt='' />
            </div> */}
            <div className='workshopimg d-flex justify-content-between align-items-center mt-5'>
              <div className='worshopimgfirst'>
                <img src={workshopimg1} alt='' />
              </div>
              <div className='workshopgroup2'>
                <div className='mb-4'>
                  <img src={workshopimg2} alt='' />
                </div>
                <div className=''>
                  <img src={workshopimg3} alt='' />
                </div>
              </div>
            </div>
            <div className='seminarcontent'>
              <h1>Training/Workshop/Seminar</h1>
              <h4>Driver Training</h4>
              <p>
                The purpose of our training is to provide basic orientation on
                road safety awareness and defensive driving techniques to
                Drivers cut across all industries in other to reduce the
                frequency of auto crashes, and ensure safety of citizens and
                vehicles on our highways.Experience has shown that the rate at
                which drivers are involved in road crashes is directly
                attributable to lack of basic knowledge of new technology in
                road safety requirements and defensive driving techniques. Our
                training is useful for drivers of Government MDAs, Senior
                Executive Drivers, Tanker/Logistics company drivers, Operations
                and Pool Drivers attached to private High Net worth Individuals,
                Chief Executives, Directors and Deputies, Principal Managers and
                Managers in Public Sector Organizations. Also, the Lagos State
                Mandatory Annual Driver Re-certification Card will be issued at
                the end of the exercise to all participating drivers if they do
                not have any or have expired.
              </p>
            </div>
          </div>
          <div className='workshop d-flex justify-content-between flex-column mb-5'>
            <div className='workshopcontent mt-5'>
              <h1>Workshop Content</h1>
              <p>
                The training programme covers wide spectrum of modern corporate
                drivers learning and development needs, and is subdivided into 3
                Stages namely <b>Basic</b>, <b>Intermediate</b> and{' '}
                <b>Advanced</b>. Depending on your company’s immediate needs,
                you can choose any of the training modules that will be okay for
                you.
              </p>
              <h5>NOTE</h5>
              <h4>The Fee covers:</h4>
              <ul>
                <li>Training Materials</li>
                <li>Seminar Leather Bag</li>
                <li>NO NAP Safe Driving Device</li>
                <li>Feeding and Refreshments</li>
                <li>Tea/Coffee Breakfast</li>
                <li> Copy of the New FRSC Highway Code</li>
                <li> Certificate of Attendance</li>
                <li> Group photograph</li>
              </ul>
            </div>
            <div className='coursesss mt-5'>
              <div className='module'>
                <h4> MODULE I</h4>
                <h4> (BASIC COURSE)</h4>
                <ul>
                  <li>Causes, effects and preventive of RTC</li>
                  <li>Basic Defensive driving Techniques </li>
                  <li>Sharing the road with other road users</li>
                  <li>Basic Automobile Fire prevention techniques</li>
                  <li>Routine checks and routine maintenance</li>
                  <li>Review Of Nigerian High Way Code</li>
                  <li>
                    Medical Tests ( Blood Pressure, blood Sugar level, vision
                    acuity tests)
                  </li>
                  <li>Work Ethics</li>
                  <li>Practical driving checks</li>
                </ul>
                <p>
                  <b>Duration</b> : 3 Days (Thursdays, Fridays and Saturdays
                  within same month).
                </p>
                <p>
                  <b>Time</b> : 8.00am – 5:00pm
                </p>
                <p>
                  <b>Workshop Fee</b>: N90, 500.00 per participant
                </p>
              </div>
              <div className='module'>
                <h4> MODULE II</h4>
                <h4> (INTERMEDIATE COURSE)</h4>

                <ul>
                  <li>Defensive driving Techniques II</li>
                  <li>Promoting professionalism in Driving Industry </li>
                  <li>Collision prevention formula</li>
                  <li>Executive Protection/Security driving technique</li>
                  <li>Driver and the Law ( Traffic Laws)</li>
                  <li>Stress Management I</li>
                  <li>Traffic Signs and Codes</li>
                  <li>Safety guidelines for driving in special condition</li>
                </ul>
                <p>
                  <b>Duration</b> : 3 Days (Thursdays, Fridays and Saturdays
                  within same month).
                </p>
                <p>
                  <b>Time</b> : 8.00am – 5:00pm
                </p>
                <p>
                  <b>Workshop Fee</b>: N100, 500.00 per participant
                </p>
              </div>
              <div className='module'>
                <h4> MODULE III</h4>
                <h4> (ADVANCED COURSE)</h4>

                <ul>
                  <li>Drugs/Alcohol in driving</li>
                  <li>Psychology of Drivers</li>
                  <li>SEffective Journey Management</li>
                  <li>
                    Introduction to Crash Investigation & Intelligent fleet
                    management in Nigeria
                  </li>
                  <li>First Aid administration and rescue techniques</li>
                  <li>Tyre and Tyre care</li>
                  <li>
                    National Uniform Licensing Scheme procedures for obtaining
                    NDL and Vehicle Number Plates.
                  </li>
                  <li>Traffic Signs and Codes</li>
                  <li>Safety guidelines for driving in special condition</li>
                </ul>
                <p>
                  <b>Duration</b> : 3 Days (Thursdays, Fridays and Saturdays
                  within same month).
                </p>
                <p>
                  <b>Time</b> : 8.00am – 5:00pm
                </p>
                <p>
                  <b>Workshop Fee</b>: N120, 500.00 per participant
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Seminars
