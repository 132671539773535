import React from 'react'
import nonapworksimg from '../../../Images/Rectangle 111.png'
import nonapworksimg2 from '../../../Images/Rectangle 112.png'
import nonapworksimg3 from '../../../Images/Rectangle 110.png'
import nonapworksimg4 from '../../../Images/Rectangle 85.png'
import nonapworksimg5 from '../../../Images/Rectangle 86.png'
import nonapimg from '../../../Images/nonap1.png'
// import { useLocation } from 'react-router-dom'


import '../../Services/NoNap/Nonapworks.css'
const Nonapworks = () => {
  return (
    <>
      <div className='heronap'>
        <div className='nonapdescript d-flex justify-content-center align-items-center flex-column '>
          <h1>No Nap Kit</h1>
          <p>
            No Nap is an anti-drowsy alert safety device, which helps a fatigued
            driver to overcome the dizzy feeling. No Nap is found to be very
            effective in breaking this thin sheet of drowsiness in which the
            driver is caught unawares and helps to recover his presence of mind
            and concentration.
          </p>
        </div>
      </div>
      <div className='features d-flex justify-content-start align-items-center flex-column pt-4'>
        <div className='feature'>
          <ul>
            <h4>Features :</h4>

            <li>Alarming Angle - 15 To 20 Degrees (Adjustable)</li>
            <li>
              Sound Pressure: less than 100 db (at 1 cm away from the gadget)
            </li>
            <li>Power Source: DC 3V</li>
            <li>Colour: Black</li>
            <li>Body: ABS grade plastic</li>
            <li>Packing: Blister Packing</li>
          </ul>
          <div className='featuresimg'><img src={nonapimg} alt="" /></div>
        </div>
      </div>
      <div className='nonapworks d-flex justify-content-center align-items-center flex-column pt-4'>
        <div className='nopworkcontent1'>
          <h1>How NO NAP Works</h1>
          <p>
            NO NAP is worn on the left ear. If the drivers head goes down,
            either due to tiredness, sleep or distraction, the system detects it
            and provides a warning “BEEP” to alert the driver and bring him to
            his presence of mind.
          </p>
        </div>
        <div className='d-flex flex-column justify-content-between align-items-center napworks m-4'>
          <div className='imgwork d-flex justify-content-between align-items-center my-4'>
            <div className='napworksimg '>
              <img src={nonapworksimg3} alt='' />
            </div>
            <div className='napworksimg2'>
              <div className='mb-4'>
                <img src={nonapworksimg} alt='' />
              </div>
              <div className=''>
                <img src={nonapworksimg2} alt='' />
              </div>
            </div>
          </div>
          <p>
            This eliminates the possibility of driving while fatigued as it will
            keep on beeping until the drivers head is returned to the
            appropriate position.  When fatigue is acknowledged, it is
            recommended to clear very well off the road, and take a short power
            nap before carrying on the journey.
          </p>
        </div>
      </div>

      <div className='helps d-flex justify-content-center align-items-center flex-column mt-4'>
        <div className='nonapcontent'>
          <h1>How NO NAP Helps </h1>
          <p>
            NO NAP responds to the state of the driver well and increases safety
            of both the driver and the vehicle. Majority of all accidents occur
            due to driver error and driver fatigue, which is the major issue.
          </p>
        </div>
        <div className='nonaphelps d-flex justify-content-center align-items-center flex-column'>
          <div className='nonaphelpsimg d-flex justify-content-center align-items-center'>
            <div className='mb-4'>
              <img src={nonapworksimg4} alt='' />
            </div>
            <div className='mb-4'>
              <img src={nonapworksimg5} alt='' />
            </div>
          </div>
        </div>
        <div className='note mt-5 d-flex justify-content-center flex-column'>
          <p>
            <b>NOTE</b>:The device is not a cure for drowsiness.
          </p>
          <p>
            UNDER NO CIRCUMSTANCE OR GROUP OF CIRCUMSTANCES SHOULD ANY ONE
            ATTEMPT TO DRIVE AN AUTO MOBILE IF TIRED’ REGARDLESS OF WHETHER THEY
            HAVE THIS DEVICE OR NOT. IF A DRIVER KNOWS HE IS TIRED AND IS
            FEELING SLEEPY, PLEASE IT IS RECOMMENDED YOU PARK AND SLEEP FOR A
            WHILE BEFORE EMBARKING ON THE JOURNEY. THAT WAY, HE WILL MAKE THE
            ROAD SAFER FOR HIMSELF AND OTHER ROAD USERS. WHEN WE HAVE SAFER
            ROADS, WE HAVE FULLER LIVES.
          </p>
        </div>
      </div>
    </>
  )
}

export default Nonapworks
