import React from 'react'
import { Link } from 'react-router-dom'



import '../Home/Hero.css'
const Hero = () => {
  return (
    <div className='hero d-flex justify-content-center'>
      <div className='container-fluid cont'>
        <h1>
          <span>No Nap Device,</span>
          <br /> Keeps You and Your Car Safe.
        </h1>
        <p>
          NO NAP Nig. Ltd, is a company whose sole aim among other things is
          contribute to reducing road crashes on our roads.
        </p>
        <div className=''>
          <Link
            to='/contactUs'
            className='text-decoration-none text-capitalize bookservice'
          >
            book services
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hero