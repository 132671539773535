import React from 'react'
// import Header from '../../../Header'
// import Layout from '../../../Pages/Layout'
import Seminars from '../Compnents/landingpages/Services/Training/Seminars'
import Footer from '../Footer'
import Header from '../Header'
import Layout from './Layout'
// import { useLocation } from 'react-router-dom'

const Training = (props) => {
    //  const routepath = useLocation()
    //  useEffect(() => {
    //    window.scrollTo(0, 0)
    //  }, [routepath])
  return (
    <>
      <Layout>
        <Header />
        <Seminars />
        <Footer/>
      </Layout>
    </>
  )
}

export default Training
