import React from 'react'
import { Link } from 'react-router-dom'
import nonapkit from '../../Images/nonapkit.png'
import training from '../../Images/trainng.png'
import speedlimiters from '../../Images/speedlimiters (2).png'
import tips from '../../Images/tips.png'
// import { useLocation } from 'react-router-dom'

import '../Home/OurServices.css'
const OurServices = () => {
    // const routepath = useLocation()
    // useEffect(() => {
    //   window.scrollTo(0, 0)
    // }, [routepath])
  return (
    <div className='heroservices d-flex justify-content-center'>
      <div className='container-fluid d-flex justify-content-center align-items-center flex-column services pt-5 pb-5'>
        <h1>Our Services</h1>
        <div className='d-flex align-items-center flex-column services-item w-100 mt-5'>
          <div className='d-flex align-items-center flex-column justify-content-around flex-md-row nonapkit w-100'>
            <div className='nonapkit-img'>
              <img src={nonapkit} alt='' />
            </div>
            <div className='nonaptext'>
              <h3>No Nap Kit</h3>
              <p>
                No Nap kit responds to the state of the driver wellness and
                increases safety of both the driver and the vehicle.
              </p>
              <div className='readmore'>
                <Link to='/nonap'>Read More...</Link>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-around w-100 flex-column flex-md-row-reverse training mt-5'>
            <div className='training-img'>
              <img src={training} alt='' />
            </div>
            <div className='trainingtext'>
              <h3>Training/Workshop</h3>
              <p>
                No Nap purpose of our training is to provide basic orientation
                on road safety awareness and defensive driving techniques to
                Drivers cut across all industries No Nap aimed at achieving the
                dissemination of Road Safety tips.
              </p>
              <div className='readmore'>
                <Link to='/training'>Read More...</Link>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center flex-column justify-content-around flex-md-row mt-5 speedlimiter'>
            <div className='speedlimiter-img'>
              <img src={speedlimiters} alt='' />
            </div>
            <div className='speedlimitertext'>
              <h3>Speed Limiters</h3>
              <p>
                Speed Limiters provide remote asset management. These products
                are uniquely designed to address all the tracking concerns. They
                come as stand-alone units,with built-in configuration and
                management systems.
              </p>
              <div className='readmore'>
                <Link to='/speedlimiters'>Read More...</Link>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-around flex-column flex-md-row-reverse mt-5 freetips'>
            <div className='freetips-img'>
              <img src={tips} alt='' />
            </div>
            <div className='freetipstext'>
              <h3>Road Free Tips</h3>
              <p>
                We also provides safety tips for drivers and it is dedicated to
                ensuring safety on our high ways and local trunks.
              </p>
              <div className='readmore'>
                <Link to='/freetips'>Read More...</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurServices
