import React, { useState} from 'react'
import faqimg from '../../Images/faq.png'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { Link } from 'react-router-dom'
// import { useLocation } from 'react-router-dom'

import '../Faqpage/Faqq.css'

const speedlimit = [
  {
    question: 'What is a speed limiting device?',
    answer:
      'A speed limiting device sometimes referred to as governor’ is used for controlling the speed of a vehicle or a device used to measure or regulatethe speed of a vehicle.',
  },
  {
    question: 'How does the speed limiting device work in a vehicle?',
    answer:
      'This device does not allow the driver to accelerate beyond the stipulated speed limit by law as has been programmed in the vehicle. So no matter how the driver tends to accelerate, the pre-set speed limit will not be exceeded.',
  },
  {
    question: 'How many types of speed limiting devices are in used?',
    answer:
      'There are two (2) major types of speed limiting devices. A. Mechanical speed limiting device.B. The Electrical/ Electronic speed limiting device.',
  },
  {
    question: 'Who are the approved marketers of speed limiting device?',
    answer:
      'The approved marketers are marketers certified by the major technical committee members which includes Standard Organisation of Nigeria (SON), National Automotive Council (NAC) and FRSC.',
  },
  {
    question:
      'Who are the technicians responsible for installation of speed limit device?',
    answer:
      'The approved marketers are responsible for the installation of the speed limit device through their agents (Technicians) who are trained and certified for that purpose.',
  },
  {
    question:
      'How would the workability/functionality of the device be checked after installation by the users?',
    answer:
      'Users are not allowed to tamper with the device but inform installers of any malfunction where they occur.',
  },
  {
    question:
      'When is the date for enforcement of speed limit device in Nigeria.',
    answer: '1st June 2015..',
  },
  {
    question: 'What is the cost of the device?',
    answer: 'The price is determined by the market force',
  },
  {
    question:
      'Who carries out servicing or repairs of the speed limiting device where there is service failure?',
    answer:
      'The approved marketers through their certified agents carries out periodic service/calibration of the device based on Standard Organisation of Nigeria (SON’s) specification.',
  },
  {
    question:
      'In the case of emergency, how would the speed be increased beyond the limit in other to avert danger at a certain point',
    answer:
      'The device will not allow the driver to go beyond a pre-set speed limit, so the driver is expected to apply defensive driving technique.',
  },
]
const nonap = [
  {
    question: 'What is the retail price of NO NAP?',
    answer: 'The No Nap Kit retails for N3,000.00 per unit.',
  },
  {
    question:
      'How quickly can you send No Nap devices to International customers?',
    answer:
      'No Nap can be sent via express courier EMS post within 2 days of receiving payments. The delivery time is usually 4 – 6 days to reach you by expresscourier.',
  },
  {
    question: 'What is the mode of payment?',
    answer:
      'The mode of payment is by bank wire, or payment at the bank into our account, or payment at our office where a receipt will be issued for local customers. For International Customers, it is by bank wire into our Domiciliary Account or payment by Money Gram or Western Union Money Transfer.',
  },
  {
    question:
      'Can you provide details to whom and where I can transfer payment?',
    answer:
      'Usually when customers choose to pay by bank wire, Money Gram orWestern Union, they will have to wait for our reply email to be sent. We preferto reply emails in person rather than automatically generated responders. Inour reply mail, we will outline the receiving coordinates .',
  },
  {
    question:
      'What is the difference between Standard and Express Shipping for International customers?',
    answer:
      'Express courier post (EMS) means shipment will be expedited at a higher cost, and it will take a shorter time to receive the NO NAP at your destination Standard shipment is the normal courier and would not take less than 14 days to reach customers. Some destination could even take longer.                                         Frankly speaking, if someone is prepared to wait at least 2 weeks for their order to arrive then the best choice is Standard shipment. Throughout our experience there was not a single time that our parcels went missing only delayed by 1 week (for Standard). If customers want the products in fastest possible way then it is best to choose Express because it can be traced and will reach within 1 week max. (4-6days actual).',
  },
  {
    question:
      'Please could you outline the expected product life under normal conditions? Guarantee and Repair?',
    answer:
      'The No Nap device comes with 1 year warranty from date of purchase (prove of receipt). No Nap will not deteriorate in quality of product provided proper care is exercised by the user as per direction of usage. In the event of any deterioration in quality of the products, an investigation will be carried out by us on receiving full details in the format prescribed by the us under Returns and Refunds Procedure and if it is established that the deterioration has taken place due to manufacturing mistake, we shall replace free of cost the quantity of such defective goods.',
  },
  {
    question:
      'Does the product require a battery and if so is it readily available?',
    answer:
      'Yes the No Nap comes with batteries. The shelf lives of the batteries are 1 year and 90% of capacity will be maintained after 12 months storage. Each battery is 1.5volts so the No Nap runs on total 3Volts 2x 1.5v Button cell Batteries. It can be easily found any where ie under Energiser batteries spec look for LR44 model.',
  },
  {
    question: 'My No Nap does not beep loud enough why is this so.?',
    answer:
      'The most obvious cause is that the batteries have become weak, please follow the instruction below to change the battery.  On the reverse side of the No Nap is a small battery cover. Open the cover.  Tilt the unit upside down and to allow the weak batteries to fall out.  Insert the new LR44 Button cell batteries with the smooth surface of the batteries facing up one on top of the other.  Place the battery cover back on and lock it. The unit has to be adjusted manually by tilting it forward around the ear for more sensitivity and vise-versa for less sensitivity. It is important that drivers know this functionality to make full use of the No Nap’s potential.',
  },
  {
    question:
      'Will the No Nap hinder other activities like adjusting the Radio and drinking etc ?',
    answer:
      'NO. The No Nap is designed to alert the driver if his head is nodding downwards in drowsiness. It is impossible for a driver to suddenly fall asleep while adjusting the radio or drinking (coffee and other non-alcohol drinks) while driving, so when the driver is doing something while driving he does not need the No Nap to track the motion of his head hence the driver can choose to adjust the No Nap’s sensitivity by adjusting to a less sensitive angle or totally switch it off. One of the many good uses of No Nap is that it breaks the layers of drowsiness by getting the drivers attention when driver first tries to do something while driving. That indirectly alerts the driver that he is doing something unsafe and should focus on the road rather then off the road. At this point the driver gets more alert then he would be if there was nothing to get his attention in the first place. However, it is advised not to drink anything while driving as that too is a distraction.',
  },
  {
    question:
      'Won&#39;t this unit actually give drivers false confidence or security and encourage them to drive when tired?',
    answer:
      'Unfortunately many drivers are already driving with a false sense of confidence and security when they drive when tired. Research has shown that whilst drivers are aware of feelings of tiredness long before their head nods, many do not take a break or take the effects of tiredness into account when planning their journey. This problem is one of Culture and education similar to drink driving and will likely take decades to address.The purpose of the Antidote as a tiredness awareness device is two fold: To act as a visual reminder of the driver tiredness issue; To act as an added precaution for drivers.',
  },
]
const Speedlimiterspage = () => {

  const [showAnswer, setShowAnswer] = useState(null)
  const [showAnswerr, setShowAnswerr] = useState(null)

  const toggleAnswer = (index) => {
    showAnswer === index ? setShowAnswer(null) : setShowAnswer(index)
  }
  const toggleAnswerr = (index) => {
    showAnswerr === index ? setShowAnswerr(null) : setShowAnswerr(index)
  }
  return (
    <>
      <div className='faqhero py-5'>
        <div className='faqq d-flex justify-content-center align-items-center flex-column'>
          <h1>Do You Have Questions?</h1>
          <p>
            Below you will have answers to the most common questions you may
            have on No Nap & Speed Limiters. If you can not find the answers you
            are looking for, just{' '}
            <Link to='/contactUs' className='contactlink'>
              Contact us!
            </Link>
          </p>
          <div className='faq'>
            <img src={faqimg} alt='' />
          </div>
        </div>
      </div>
      <div className='frequently py-5'>
        <div className='qanda'>
          <div className='nonap'>
            <h1>No Nap FAQs</h1>
            <div className='questionss mt-4'>
              {nonap.map((item, index) => (
                <div className='item'>
                  <div
                    className='question d-flex justify-content-between  align-items-center'
                    onClick={() => toggleAnswer(index)}
                  >
                    <h6>{item.question}</h6>
                    <span>
                      {showAnswer === index ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </div>
                  <div className={showAnswer === index ? 'show' : 'answer'}>
                    {item.answer}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='speedlimiters'>
            <h1>Speed Limiters FAQs</h1>

            <div className='questionss mt-4'>
              {speedlimit.map((item, index) => (
                <div className='item'>
                  <div
                    className='question d-flex justify-content-between  align-items-center'
                    onClick={() => toggleAnswerr(index)}
                  >
                    <h6>{item.question}</h6>
                    <span>
                      {showAnswerr === index ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </div>
                  <div className={showAnswerr === index ? 'show' : 'answer'}>
                    {item.answer}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Speedlimiterspage
