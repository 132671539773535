import React from 'react'
// import heroaboutimg from '../../Images/Rectangle 18.png'
import distributoimg from '../../Images/dristributor image.png'

import '../Aboutus/Herosection.css'
// import { useLocation } from 'react-router-dom'

const Herosection = () => {
  return (
    <>
      <div className='heroabout'>
        <div className='heroaboutimg nonapdescript d-flex justify-content-center align-items-center flex-column '>
          <h1>No NAP Nig Ltd</h1>
          <p>
            NO NAP Nig. Ltd, is a company whose sole aim among other things is
            to contribute to reducing road crashes on our roads
          </p>
        </div>
        <div className='aboutcontent mt-3'>
          <h1>About Us</h1>
          <p>
            NO NAP Nig. Ltd, is a company whose sole aim among other things is
            to contribute to reducing road crashes on our roads through the
            sales and marketing of NO NAP Anti Drowsy Alert Device Brand,
            Vehicle Speed Limiters, Mobile Security, as well as organize
            Training, Seminars and workshops on Road Safety for drivers, in
            conjunction with other stake holders in the Road safety industry
            such as the FRSC, LASTMA, Nigerian Army Ordinance Corps ( Transport
            Division), Nigerian Motor Institute, just to mention a few. Our
            Workshop/Seminars is aimed at achieving the dissemination of Road
            Safety tips to the driving public, pedestrians and other road users
            ,with a view of reducing the carnage on our roads. When we have
            safer roads, we enjoy fuller lives.
          </p>
        </div>
        <div className='distributor d-flex justify-content-center align-items-center flex-column'>
          <h1>Distributor Network</h1>
          <div className='distributorimg w-100 d-flex justify-content-center align-items-center'>
            <img src={distributoimg} alt='' />
          </div>
          <div className='lagos w-100'>
            <h4>Lagos</h4>
            <div className='locationL'>
              <div className='l1'>
                <h5>Diva Makeovers and Collections,</h5>
                <p>
                  Shop 4, POWA Line Shops, Ogba B/S, Ogba, Ikeja, Lagos.
                  Tel: 0806 082 7331 Email: info@divamakeovers.com
                  Web: www.divamakeovers.com
                </p>
              </div>
              <div className='l1'>
                <h5>Nigerland Consult Ltd,</h5>
                <p>
                  Number 4, Elkana St, Behind St Jude Nur/Pry School, Mangoro,
                  Agege, Lagos. Tel: 0803 300 0567
                  Email: contact@nigerlandconsult. com
                  Web: www.nigerlandconsult.com
                </p>
              </div>
              <div className='l1'>
                <h5>Passion Field Global Concept Ltd,</h5>
                <p>
                  Plot 2,Jobifele Way, Opp Mind Builders School,(CBD),
                  Agidimgbi, Ikeja. Tel: 0803 395 0787 
                  Mail: passionfield@yahoo.ca
                </p>
              </div>
            </div>
          </div>
          <div className='lagoss w-100 mt-4'>
            <div className='abuja'>
              <h4>Abuja FCT</h4>
              <div className='location'>
                <div className='l1'>
                  <h5>Rem-Jacsino Ventures,</h5>
                  <p>
                    Suite 16,Blk 1,POWA Plaza, Nyanya, Abuja. Tel: 0805 292 1691
                    Email: achor.orji@yahoo.com, dasreinv@yahoo.com
                  </p>
                </div>
              </div>
            </div>
            <div className='abia'>
              <h4>Abia</h4>
              <div className='location'>
                <div className='l1'>
                  <h5>Agbanyim Ventures Ltd,</h5>
                  <p>
                    20 Enugwu-Ukwu St., World Bank Housing Estate, Umuahia, Abia
                    State. Tel: 0813 899 4207 Mail: ify_ahams@yahoo.com
                  </p>
                </div>
              </div>
            </div>
            <div className='oyo'>
              <h4>OYO</h4>
              <div className='location'>
                <div className='l1'>
                  <h5>Amordoz Multiple Concept,</h5>
                  <p>
                    A16, Amazing Grace Shopping Complex, Arulogun Road, Ojoo
                    Ibadan, Oyo State. Tel:0817 749 3227 
                    Email: amordoz@yahoo.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Herosection
