import React from 'react'
import Herosection from '../Compnents/landingpages/Aboutus/Herosection'
import Footer from '../Footer'
import Header from '../Header'
import Layout from './Layout'
// import { useLocation } from 'react-router-dom'

const AboutUs = (props) => {
  // const routepath = useLocation()
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [routepath])
  return (
    <>
      <Layout>
        <Header />
        <Herosection />
        <Footer />
      </Layout>
    </>
  )
}

export default AboutUs
