import React, { useState, useEffect } from 'react'
import logo from './Compnents/Images/Logo.png'
import { Link } from 'react-router-dom'
import { FaTimes, FaAlignLeft } from 'react-icons/fa'
// import { MdOutlineArrowDropDown } from 'react-icons/md'
// import { useLocation } from 'react-router-dom'

import '../src/Header.css'
const Header = () => {
  const [toggle, setToggle] = useState(false)

  const [isSticky, setSticky] = useState(false)
  //  const headerRef = React.useRef(null)
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 50) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  return (
    <div
      // className='header d-flex justify-content-center align-items-center'
      className={
        isSticky
          ? ''
          : 'header d-flex justify-content-center align-items-center'
      }
      // className={`header header d-flex justify-content-center align-items-center ${
      //   isSticky ? 'sticky' : ''
      // }`}
      // container-fluid
      // d-flex
      // justify-content-between
      // align-items-center
    >
      <nav
        className='container-fluid d-flex justify-content-between align-items-center'
      >
        <Link to='/' className='logo'>
          <img src={logo} alt='LOGO' className='img-fluid' />
        </Link>
        <ul className='nav_menu d-none d-lg-flex justify-content-center align-items-center p-0 m-0'>
          <li className='link mx-3 text-capitalize'>
            <Link to='/' className='link1'>
              home
            </Link>
          </li>
          <li className='link mx-3 text-capitalize dropdown'>
            <Link
              to=''
              className='dropdown-toggle link1'
              id='dropdownMenuLink'
              role='button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              Services
            </Link>
            <ul class='dropdown-menu' aria-labelledby='dropdownMenuLink'>
              <li>
                <Link to='/nonap' className='dropdown-item link'>
                  No Nap Kit
                </Link>
              </li>
              <li>
                <Link to='/training' className='dropdown-item link'>
                  Training
                </Link>
              </li>
              <li>
                <Link to='/speedlimiters' className='dropdown-item link'>
                  Speed LImiters Devices
                </Link>
              </li>
              {/* <li>
                <Link to='/freetips' className='dropdown-item link'>
                  Free Tips
                </Link>
              </li> */}
              <li>
                <Link to='/gpstracking' className='dropdown-item link'>
                  GPS Tracking
                </Link>
              </li>
            </ul>
          </li>
          <li className='link mx-3 text-capitalize'>
            <Link to='/freetips' className='link1'>
              Free Tips
            </Link>
          </li>
          <li className='link mx-3 text-capitalize'>
            <Link to='/AboutUs' className='link1'>
              about us
            </Link>
          </li>
          <li className='link mx-3 text-capitalize'>
            <Link to='/contactUs' className='link1'>
              contact us
            </Link>
          </li>
        </ul>
        <div className='bookusbutton d-none d-lg-block'>
          <Link
            to='/contactUs'
            className='text-decoration-none text-capitalize link2'
          >
            book Training
          </Link>
        </div>
        <div className='smallscreen'>
          <FaAlignLeft
            color='#E06D06'
            fontSize={27}
            onClick={() => {
              setToggle(true)
            }}
          />
          {toggle && (
            <div className='smallnav'>
              <FaTimes
                color='#f5f6ff'
                className='close'
                onClick={() => {
                  setToggle(false)
                }}
              />
              <ul className='nav_menu d-flex justify-content-center align-items-center flex-column p-0'>
                <li
                  className='link my-2 text-capitalize'
                  onClick={() => {
                    setToggle(false)
                  }}
                >
                  <Link to='/' className='link1'>
                    home
                  </Link>
                </li>
                <li className='link my-2 text-capitalize dropdown'>
                  <Link
                    to=''
                    className='dropdown-toggle link1'
                    id='dropdownMenuLink'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    Services
                  </Link>
                  <ul class='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                    <li>
                      <Link
                        to='/nonap'
                        className='dropdown-item link'
                        onClick={() => {
                          setToggle(false)
                        }}
                      >
                        No Nap Kit
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/training'
                        className='dropdown-item link'
                        onClick={() => {
                          setToggle(false)
                        }}
                      >
                        Training
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/speedlimiters'
                        className='dropdown-item link'
                        onClick={() => {
                          setToggle(false)
                        }}
                      >
                        Speed Limiters Devices
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to='/freetips'
                        className='dropdown-item link'
                        onClick={() => {
                          setToggle(false)
                        }}
                      >
                        Free Tips
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to='/gpstracking'
                        className='dropdown-item link'
                        onClick={() => {
                          setToggle(false)
                        }}
                      >
                        GPS Tracking
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className='link my-2 text-capitalize'
                  onClick={() => {
                    setToggle(false)
                  }}
                >
                  <Link to='/freetips' className='link1'>
                    Free Tips
                  </Link>
                </li>
                <li
                  className='link my-2 text-capitalize'
                  onClick={() => {
                    setToggle(false)
                  }}
                >
                  <Link to='/AboutUs' className='link1'>
                    about us
                  </Link>
                </li>
                <li
                  className='link my-2 text-capitalize'
                  onClick={() => {
                    setToggle(false)
                  }}
                >
                  <Link to='/contactUs' className='link1'>
                    contact us
                  </Link>
                </li>
              </ul>
              <div className='bookusbutton'>
                <Link
                  to='/contactUs'
                  className='text-decoration-none my-2 text-capitalize link2'
                  onClick={() => {
                    setToggle(false)
                  }}
                >
                  book Training
                </Link>
              </div>
            </div>
          )}
        </div>
      </nav>
    </div>
  )
}

export default Header
