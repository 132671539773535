import React from 'react'
import idk from '../../Images/idk.png'
import lastma from '../../Images/lastma.png'
import frsc from '../../Images/frsc.png'



import '../Home/Ourpartners.css'
const Ourpartners = () => {
  return (
    <>
      <div className='container-fluid d-flex align-items-center flex-column mt-5 partner w-100'>
        <h1>Our Partners</h1>
        <div className='ourpartnersimg d-flex justify-content-between align-items-center'>
          <div className='idk'>
            <img src={idk} alt='' className='img-fluid' />
          </div>
          <div className='lastma'>
            <img src={lastma} alt='' className='img-fluid' />
          </div>
          <div className='frsc'>
            <img src={frsc} alt='' className='img-fluid' />
          </div>
        </div>
      </div>
    </>
  )
}

export default Ourpartners