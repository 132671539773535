import React, {useState } from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { Link } from 'react-router-dom'

// import { useLocation } from 'react-router-dom'


import './Faq.css'

const Data = [
  {
    question: 'What is a speed limiting device?',
    answer:
      'A speed limiting device sometimes referred to as governor’ is used for controlling the speed of a vehicle or a device used to measure or regulatethe speed of a vehicle.',
  },
  {
    question: 'How does the speed limiting device work in a vehicle?',
    answer:
      'This device does not allow the driver to accelerate beyond the stipulated speed limit by law as has been programmed in the vehicle. So no matter how the driver tends to accelerate, the pre-set speed limit will not be exceeded.',
  },
  {
    question: 'How many types of speed limiting devices are in used?',
    answer:
      'There are two (2) major types of speed limiting devices. A. Mechanical speed limiting device.B. The Electrical/ Electronic speed limiting device.',
  },
  {
    question: 'Who are the approved marketers of speed limiting device?',
    answer:
      'The approved marketers are marketers certified by the major technical committee members which includes Standard Organisation of Nigeria (SON), National Automotive Council (NAC) and FRSC.',
  },
  {
    question:
      'Who are the technicians responsible for installation of speed limit device?',
    answer:
      'The approved marketers are responsible for the installation of the speed limit device through their agents (Technicians) who are trained and certified for that purpose.',
  },
  {
    question: 'How would the workability/functionality of the device be checked after installation by the users?',
    answer:
      'Users are not allowed to tamper with the device but inform installers of any malfunction where they occur.',
  },
]

const Faq = () => {
  //  const routepath = useLocation()
  //  useEffect(() => {
  //    window.scrollTo(0, 0)
  //  }, [routepath])
  const [showAnswer, setShowAnswer] = useState(null)

  const toggleAnswer = (index) => {
    showAnswer === index ? setShowAnswer(null) : setShowAnswer(index)
  }
  return (
    <>
      <div className='container-fluid d-flex justify-content-center align-items-center flex-column faq pt-5 pb-5'>
        <h1 className='text-center'>Do You have a Question?</h1>
        <h2 className='text-center'>
          We have answer to most common question you may have on No Nap{' '}
        </h2>
        <div className='questions mt-4'>
          {Data.map((item, index) => (
            <div className='item'>
              <div
                className='question d-flex justify-content-between  align-items-center'
                onClick={() => toggleAnswer(index)}
              >
                <h6>{item.question}</h6>
                <span>
                  {showAnswer === index ? <FaCaretDown /> : <FaCaretUp />}
                </span>
              </div>
              <div className={showAnswer === index ? 'show' : 'answer'}>
                {item.answer}
              </div>
            </div>
          ))}
        </div>
        <div className='seemore'>
          <Link to='/faq'>see more....</Link>
        </div>
      </div>
    </>
  )
}

export default Faq