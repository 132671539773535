import React from 'react'
import Nonapworks from '../Compnents/landingpages/Services/NoNap/Nonapworks'
import Footer from '../Footer'
import Header from '../Header'
import Layout from './Layout'
// import { useLocation } from 'react-router-dom'

const Nonapkit = (props) => {
    // const routepath = useLocation()
    // useEffect(() => {
    //   window.scrollTo(0, 0)
    // }, [routepath])
  return (
    <>
      <Layout>
        <Header />
        <Nonapworks/>
        <Footer/>
      </Layout>
    </>
  )
}

export default Nonapkit
